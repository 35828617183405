import { createStandaloneToast } from "@chakra-ui/toast";
import { addSeconds, format, parseISO } from "date-fns";
import { ggSentry } from "../config/sentry";
import { PRODUCTION_MODE } from "../constants";
import { StorageService } from "../service/Storage";

export const isProdMode = StorageService.get(PRODUCTION_MODE) === true;
export function logout() {
  StorageService.setAccessToken();
  StorageService.setRefreshToken();
  StorageService.setUserId();
  StorageService.setWId();
  StorageService.setXPN();
}

export const toast = createStandaloneToast();

/**
 * @param {Promise<any> | (() => Promise<any>)} fn Async Function or Promise
 * @param {Boolean | string} successMsg  Default is `false`. Set `true` for default message or pass custom string msg.
 * @param {Boolean | string} errorMsg  Default is `true`. Set `true` for default message or pass custom string msg.
 * @param {Boolean} propagateError  Default is `true`. Set `false` to stop propagate Error from this function
 */
export const withDefaultUIHandler = async (
  fn,
  successMsg = false,
  errorMsg = true,
  propagateError = true
) => {
  try {
    const data = await (fn.then ? fn : fn());
    if (successMsg) {
      //@todo
    }
    return data;
  } catch (error) {
    if (
      error?.response?.err_code === 1003 ||
      error?.response?.err_code === 1004 ||
      error?.data?.err_code === 1003 ||
      error?.data?.err_code === 1004
    ) {
      throw error;
    }
    if (!String(error?.response?.status || "").startsWith("4")) {
      ggSentry.catchExceptions(error);
    }
    if (errorMsg !== false) {
      console.error(errorMsg === true ? formatServerErrors(error) : error);
      toast({
        title: errorMsg === true ? formatServerErrors(error) : error,
        status: "error",
        isClosable: true,
      });
    }
    if (propagateError) {
      throw error;
    }
  }
};

export function formatServerErrors(error) {
  const serverErrorMsg =
    error?.response?.data?.error || error?.response?.data?.err;

  if (serverErrorMsg && typeof serverErrorMsg === "string") {
    return [serverErrorMsg];
  }

  if (error?.response) {
    const responseData = error.response.data;
    const statusCode = error.response.status;

    if (statusCode === 503 || statusCode === 502) {
      return ["Service down. Please try again."];
    }
    if ([413].includes(statusCode)) {
      return [`The file you are trying to upload is too large`];
    }
    if (responseData && typeof responseData === "string") {
      return [responseData];
    }
    if ([404, 400, 500, 501, 405].includes(statusCode)) {
      return ["Some error occurred, please try again"];
    }

    if ([403, 401].includes(statusCode)) {
      return [`Forbidden. You're not allowed to perform the action`];
    }

    if (typeof responseData === "string" && responseData.includes("Network")) {
      //"Network Error caught"
      if (window.navigator.onLine) {
        return ["Server Error"];
      }
      return ["Network Error or Service down"];
    }

    return ["Some error occurred, please try again"];
  }

  if (error.message && typeof error.message === "string") {
    return !window.navigator.onLine
      ? ["No active internet connection!"]
      : [error.message];
  }

  return ["Some error occurred, please try again"];
}

export function paisaToRupee(paisa) {
  if (!paisa) return 0;
  return Number(paisa / 100).toFixed(2);
}

export const calculatePercentage = (current, base) => {
  const value = (Number(current) / Number(base)) * 100;
  if (isNaN(value)) {
    return 0;
  } else if (value === Infinity) {
    return 0;
  } else {
    return +Number(value).toFixed(2);
  }
};

export function formatNumber(number) {
  return new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(
    number
  );
}

export function formatDecimal(num) {
  if (num % 1 !== 0) {
    return parseFloat(num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  } else {
    return parseFloat(num).toFixed(0);
  }
}

export function formatBigNumber(num, threshold = 1e3) {
  if (num <= 999999) {
    return formatNumber(num || 0);
  }
  // Define the thresholds for K, M, B, T, etc.
  const thresholds = [
    [1e12, "T"],
    [1e9, "B"],
    [1e6, "M"],
    [1e3, "K"],
  ];

  // Run the loop only when the threshold value is less than the provided threshold
  if (num > threshold) {
    for (const [thresholdValue, suffix] of thresholds) {
      if (num >= thresholdValue) {
        const formattedNum = num / thresholdValue;
        // If the number is a decimal, truncate decimal places to 2 digits without rounding
        const formattedString = formatDecimal(formattedNum);
        return formattedString + suffix;
      }
    }
  }

  // If the number is below the specified threshold or above the highest threshold, return it as is
  return formatDecimal(num);
}

export function formattedTime(seconds) {
  var helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, "mm:ss");
}

export const secondsToMinutes = (seconds) =>
  Math.floor(seconds / 60) + ":" + ("0" + Math.floor(seconds % 60)).slice(-2);

export async function copyToClickBoard(str) {
  str = String(str);
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(str);
      return true;
    } else {
      const input = document.createElement("input");
      input.style = "position: absolute; z-index: 100000000;  top: 0";
      input.value = str;
      document.body.append(input);
      input.focus();
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      return true;
    }
  } catch (error) {
    console.log("Error", error);
    return false;
  }
}

export function secondsToHms(d) {
  d = Number(d);
  var hour = Math.floor(d / 3600);
  var minutes = Math.floor((d % 3600) / 60);
  var seconds = Math.floor((d % 3600) % 60);

  return {
    hour,
    minutes,
    seconds,
  };
}

export function isValidEmail(email) {
  if (!email) return false;
  const regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function isValidRegex(regex, value) {
  let regexStr = decodeURIComponent(regex);
  if (!value) return false;
  return new RegExp(regexStr).test(value);
}
export const currencyMap = {
  INR: "₹",
  USD: "$",
};
// make a currency map and add a fallback
export const getCurrencySymbol = (currency) => {
  return currencyMap?.[currency] || currency;
};

export function generateUniqueKey() {
  return Date.now() + Math.random().toString(36).substr(2, 9);
}

export function snakeCaseToPascaleCaseWithSpace(str) {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
export function isImageLoaded(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => reject(false);
    img.src = src;
  });
}
export function trimSpacesFromString(str) {
  if (!str) return "";
  let isMultiline = str.includes("\n");
  if (isMultiline) {
    return str
      .split("\n")
      .map((d) => d?.trim())
      .join("\n");
  }
  return str.trim();
}
